.coolProductsTextAlign {
  display: flex;
  width: 75%;
}

.coolProductsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  align-items: stretch;
}

.titleStyle {
  background: linear-gradient(var(--gradient-red), var(--gradient-dark-red));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: var(--font-m); 
}

.descriptionStyle {
  font-size: var(--font-s);  
}

.descriptorContainer {
  width:  100%;
  position: absolute;
}
.imageContainer {
  height: 450px;
  width: 100%;
  }

.image {
  height: 100%;
  width: 102%;
  object-fit: cover
}

@media (min-width: 768px) {
  .coolProductsContainer{
    position: relative;
  }
  .descriptorContainer {
  width: 60%;
  z-index: 1;
left: 65px;
  }
  .coolProductsTextAlign {
    align-self: flex-start
  }
  .descriptionStyle {
        font-size: var(--font-sm);
  }
  .imageContainer{
    height:100%;
  }
}
@media (min-width: 1024px) {
.titleStyle{
  font-size: var(--font-ml)
}
.descriptorContainer{
  top:60px;
  width: 65%;
}
}
@media (min-width: 1366px){
.descriptorContainer {
  left: 125px;
  top:175px
}}