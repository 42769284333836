.container {
  height: auto;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.textContainer {
  margin-right: 10px;
  color: var(--primary-color);
}

.buttonContainer {
  cursor: pointer;
  background-color: white;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: 2px solid var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.remove_border {
  border: none;
}

.light_text {
  color: white;
}
