.container {
  display: flex;
  flex-direction: column;
  width: auto;
  align-items: center;
  height: auto;
  padding: 10px;
  justify-content: center;
}
.description {
  width: 100%;
}
.image_container {
  width: 90%;
  height: 55px;
}
.image {
  height: 100%;
}
.KnowMoreContainer {
  width: auto;
}
@media (min-width: 768px) {
  .container {
    justify-content: flex-start;
  }
}
@media (min-width: 1024px) {
  .image_container{
    height: 70px;
  }
}
@media (min-width: 1440px) {
  .image_container{
    height: 100px;
  }
}
@media (min-width: 1920px) {
  .image_container{
    height: 110px;
  }
}
